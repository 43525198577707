body {

  background-color: black;
  color : white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img{
  margin: 25px;
  height: auto;
  width:  auto;
}

#texto{
  
  text-align: justify;
  margin: 20px;

}
